// Inspired by Tairo's useNuiDefaultProperty.
// That works like this: const rounded = useNuiDefaultProperty(props, 'BaseCard', 'rounded')
// The first argument are the components props, the second is the component name, the third is the property name.
// So they first try to find the property in the components props, if not found, it will try to find it in the nui config.
// We want to do something similar, but for our theme config.

// They have this (in the new version):
/*
  export function useNuiDefaultProperty<
    T extends Record<string, unknown>,
    C extends keyof AppConfig['nui'],
    K extends keyof T,
  >(properties: T, component: C, property: K): Ref<NonNullable<T[K]>> {
    const config = useAppConfig().nui
    return computed(() => {
      return (properties?.[property]
        ?? config?.[component]?.[property]) as NonNullable<T[K]>
    })
  }
*/

export function useThemeClasses() {
  const { defaultShapes } = useTheme()

  // Mapping of shapes to tailwind classes
  const radiuses = {
    straight: '',
    rounded: 'rounded-xl',
    curved: 'rounded-xl', // TODO
    smooth: 'rounded-xl', // TODO
  }

  const cardRadiusClass = computed(() => {
    return radiuses[defaultShapes.value?.card ?? 'rounded']
  })

  const cardShape = computed(() => {
    return defaultShapes.value?.card ?? 'rounded'
  })

  return {
    cardRadiusClass,
    cardShape,
  }
}
